// КОМПОНЕНТЫ БУТСТРАПА

import Dropdown from "bootstrap/js/dist/dropdown.js";
import Offcanvas from "bootstrap/js/dist/offcanvas.js";
import Collapse from "bootstrap/js/dist/collapse.js";
import Tooltip from "bootstrap/js/dist/tooltip.js";
import Modal from "bootstrap/js/dist/modal.js";
import Alert from "bootstrap/js/dist/alert.js";
import Tab from "bootstrap/js/dist/tab.js";
window.bootstrap = { Dropdown, Offcanvas, Collapse, Tooltip, Modal, Alert, Tab };
